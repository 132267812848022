var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("c-step", {
    attrs: {
      stepperGrpCd: "PROCEEDING_STEP_CD",
      param: _vm.param,
      currentStepCd: _vm.currentStepCd,
    },
    on: { currentStep: _vm.currentStep, closePopup: _vm.closePopup },
    model: {
      value: _vm.proceedingStepCd,
      callback: function ($$v) {
        _vm.proceedingStepCd = $$v
      },
      expression: "proceedingStepCd",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }