<template>
  <c-step
    stepperGrpCd="PROCEEDING_STEP_CD"
    :param="param"
    @currentStep="currentStep"
    :currentStepCd="currentStepCd"
    v-model="proceedingStepCd"
    @closePopup="closePopup"
  >
  </c-step>
</template>
<script>
export default {
  name: 'emergencyTrainingPlanResultDetail',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        proceedingsId: '',
        proceedingStatusCd: '',
      }),
    },
    contentHeight: null,
  },
  data() {
    return {
      proceedingStepCd: 'PSC0000001',
      editable: true,
      currentStepCd: '',
    };
  },
  watch: {
  },
  computed: {   //computed는 setter가 없으니 지정해줄 수 없음
    param() {
      return {
        proceedingsId: this.popupParam.proceedingsId,
        proceedingStatusCd: this.popupParam.proceedingStatusCd,
      }
    },
    isOld() {
      return Boolean(this.popupParam.proceedingsId)
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // code setting
      // list setting
      this.setStep();
    },
    setStep() {
      if (this.popupParam.proceedingStatusCd === 'PSC0000003') {
        this.proceedingStepCd = 'PSC0000010';
        this.currentStepCd = 'PSC0000010'
      } else if (this.popupParam.proceedingStatusCd === 'PSC0000002') {
        this.proceedingStepCd = 'PSC0000008';
        this.currentStepCd = 'PSC0000008';
      } else if (this.popupParam.proceedingStatusCd === 'PSC0000004') {
        this.proceedingStepCd = 'PSC0000010';
        this.currentStepCd = 'PSC0000010';
      } else {
        this.proceedingStepCd = 'PSC0000005';
        this.currentStepCd = 'PSC0000005';
      }
    },
    currentStep(step) {
      if (step === 'PSC0000003') {
        this.proceedingStepCd = 'PSC0000010';
        this.currentStepCd = 'PSC0000010'
      } else if (step === 'PSC0000002') {
        this.proceedingStepCd = 'PSC0000008';
        this.currentStepCd = 'PSC0000008';
      } else if (step === 'PSC0000004') {
        this.proceedingStepCd = 'PSC0000010';
        this.currentStepCd = 'PSC0000010'
      }  else {
        this.proceedingStepCd = 'PSC0000005';
        this.currentStepCd = 'PSC0000005';
      }
      this.$set(this.param, 'proceedingStatusCd', step)
    },
    closePopup() {
      this.$emit('closePopup')
    },
  }
};
</script>
